<!--
 * @Descripttion: 注册店主
 * @FilePath: /azg/src/views/register/store/index.vue
 * @Author: 张兴业
 * @Date: 2020-12-08 22:28:00
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-13 22:48:56
-->
<template>
  <div class="register">
    <div class="box">
      <register-form />
      <brand-card v-if="!isMobileTerminal" class="brand" />
    </div>
    <div class="logo">
      <img src="@/assets/index/logo@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
const RegisterForm = () => import("./components/RegisterForm");
const BrandCard = () => import("./components/BrandCard");
import { isMobileTerminal } from "@/utils/pattern.js";

export default {
  components: { RegisterForm, BrandCard },
  computed: {
    isMobileTerminal() {
      return isMobileTerminal();
    }
  }
};
</script>

<style lang="less" scoped>
.register {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(225deg, #f3f7fd 0%, #e3ebf9 100%);

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;

    .brand {
      margin-left: 30px;
    }
  }

  .logo {
    position: absolute;
    top: 38px;
    left: 60px;

    img {
      width: 46px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .register {
    .logo {
      position: absolute;
      top: 38px;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 46px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .register {
    .logo {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 36px;
      }
    }
  }
}
</style>
